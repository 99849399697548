import React from 'react';
import '@scss/main.scss';
import SpriteSheet from '@components/SpriteSheet';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const ComingSoonPage = () => {
  return (
    <>
      <SpriteSheet />
      <div className="mobile-coming-soon">
        <div className="coming-soon-text">
          <div className="coming-soon-logo">
            <SpriteIconViewer spriteId="wanpng_black" />
          </div>
          <h1>Browser is not supported</h1>
          <h2>
            Wan PNG for Employers is not available in mobile browsers. Please
            use a desktop or a laptop to access.
          </h2>
        </div>
      </div>
    </>
  );
};

export default ComingSoonPage;
